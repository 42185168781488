.box {
  margin: 0 auto;
  width: 1280px;
  height: 800px;
  background-color: red;

  .video {
    display: flex;
    justify-content: center;
    position: relative;
    top: 0;

    .ring-off {
      position: absolute;
      margin: 0 auto;
      bottom: 40px;
      z-index: 99;

    }
  }


}