.box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 1280px;
  height: 800px;
  font-size: 50px;
  color: red;
  background: #FFFFFF;

  .vosotor {
    font-size: 28px;
    color: black;
  }

}