.panel {
  display: flex;
  align-items: center;
  width: 352px;
  height: 139px;
  border-radius: 31px;
  opacity: 0.9;
  background: #333333;
  backdrop-filter: blur(54.37px);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.06);

  .panel-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-items: center;
    margin: 40px 76px;
    //border: red solid 1px;
    width: 200px;
    height: 110px;

    font-size: 28px;
    font-weight: 350;
    line-height: normal;
    text-align: center;
    letter-spacing: -0.05px;
    color: #FFFFFF;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

    .content-left {
      transition: transform 0.2s ease; /* 平滑过渡 */
      //border: red solid 1px;
      .flip {
        //color: #FFFFFF;
      }

    }

    .content-left:active {
      transform: scale(1.3);
    }

    .content-right {
      transition: transform 0.2s ease;
      //border: red solid 1px;
      .ring-off {
      }
    }

    .content-right:active {
      transform: scale(1.3);
      //border: red solid 1px;
      .ring-off {
      }
    }

    .common {
      //width: 70px;
    }

  }


}